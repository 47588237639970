// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/index.css */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f7f7f7;
}

* {
  box-sizing: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["/* src/styles/index.css */\nbody {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  background-color: #f7f7f7;\n}\n\n* {\n  box-sizing: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
